export default ({ spacing, fonts }) => ({
  container: {
    padding: [spacing * 5, 0, spacing * 12],
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },
  ul: {
    margin: 0,
    padding: spacing * 2,
    paddingLeft: spacing * 3,
  },
  li: fonts.subtitleSmall,
});
