export default ({ spacing, fonts }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    boxSizing: 'border-box',
    padding: spacing * 5,
    cursor: 'pointer',
  },

  title: {
    ...fonts.subtitleMedium,
    textAlign: 'center',
    margin: [0, 0, 30, 0],
  },

  item: fonts.subtitleSmall,
});
