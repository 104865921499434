import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import AddIcon from '@/icons/AddIcon';
import RemoveIcon from '@/icons/RemoveIcon';
import CollapseIcon from '@/icons/CollapseIcon';
import DeployIcon from '@/icons/DeployIcon';

import { contentVariants } from './utils';
import useStyles from './styles';

const DropDown = ({ title, children, className, isOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);

  const handleClick = useCallback(() => setOpen(!open), [open]);

  return (
    <div className={className}>
      <button type="button" onClick={handleClick} className={classes.titleContainer}>
        <p className={cn(classes.title, open && classes.titleLight)}>{title}</p>
        <div className={classes.imageMobileWrapper}>{open ? <CollapseIcon /> : <DeployIcon />}</div>
        <div className={classes.imageDesktopWrapper}>{open ? <RemoveIcon /> : <AddIcon />}</div>
      </button>
      <motion.div
        className={classes.contentContainer}
        variants={contentVariants}
        animate={open ? 'open' : 'close'}
        initial="close"
      >
        {children}
      </motion.div>
    </div>
  );
};

DropDown.defaultProps = {
  className: '',
  isOpen: false,
};

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default DropDown;
