export default ({ colors, spacing, fonts }) => ({
  container: {
    borderTop: ({ active }) => (active ? `3px solid ${colors.active}` : '1px solid'),
    opacity: ({ active }) => (active ? 1 : 0.6),
    paddingTop: ({ active }) => (active ? 0 : 2),
    transition: [
      ['padding-top', '0.5s', 'ease-in'],
      ['border-top', '0.5s', 'ease-in'],
      ['opacity', '0.5s', 'ease-in'],
    ],
  },
  topPart: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: [spacing * -2, 0, spacing * -2, 'auto'],
  },
  text: {
    ...fonts.subtitleSmall,
    letterSpacing: '-0.23px',
    lineHeight: 1.23,
    color: ({ active }) => (active ? colors.active : '#000'),
    marginBottom: spacing * 2,
  },
});
