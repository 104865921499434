import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <g fill="none" fillRule="evenodd">
      <path d="M28.32 18.9V8.67l-15.8 15.42h10.61a5.19 5.19 0 0 0 5.19-5.19z" />
      <path
        fill="#000"
        d="M19.17 33.11h34.62a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5H19.17a.5.5 0 1 0 0 1zM42.13 17.33h11.66a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5H42.13a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5zM42.13 21.35h11.66a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5H42.13a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5zM42.13 25.31h11.66a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5H42.13a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5zM19.17 39h34.62a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5H19.17a.5.5 0 1 0 0 1zM34.07 55.47h-14.9a.5.5 0 1 0 0 1h14.9a.5.5 0 1 0 0-1zM19.17 44.79h34.62a.5.5 0 1 0 0-1H19.17a.5.5 0 1 0 0 1zM54.29 50.13a.5.5 0 0 0-.5-.5H19.17a.5.5 0 1 0 0 1h34.62a.5.5 0 0 0 .5-.5z"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M90 59a5.1 5.1 0 0 0-4.4-3.54H62.16V14.55A7.57 7.57 0 0 0 54.6 7h-26l-1 1-15.8 15.41-1 1v38.1A7.56 7.56 0 0 0 18.35 70h20.58v15.3a7.57 7.57 0 0 0 7.56 7.56h36.25a7.57 7.57 0 0 0 7.56-7.56V63c0-.41.06-.8.06-1.2A8.65 8.65 0 0 0 90 59zm-7.28-2.57h2.79a4.08 4.08 0 0 1 3.54 2.86 4.64 4.64 0 0 1 .12 2.47 4.34 4.34 0 0 1-1.34 2.25L67.78 82.23a4.7 4.7 0 0 1-6.32 0L48 70h6.6a7.57 7.57 0 0 0 7.56-7.56v-6l20.56-.01zM28.32 8.67V18.9a5.19 5.19 0 0 1-5.19 5.19H12.52l15.8-15.42zM18.35 69a6.56 6.56 0 0 1-6.55-6.56V25.09h11.33a6.19 6.19 0 0 0 6.19-6.19V8H54.6a6.56 6.56 0 0 1 6.56 6.56v47.92A6.56 6.56 0 0 1 54.6 69H18.35zM89.3 85.34a6.56 6.56 0 0 1-6.56 6.56H46.49a6.57 6.57 0 0 1-6.56-6.56V70h6.56l14.29 13a5.71 5.71 0 0 0 7.67 0l20.07-18.19a5 5 0 0 0 .78-.89v21.42z"
      />
    </g>
  </svg>
);
