import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

class Offers extends Component {
  constructor({ offers }) {
    super();
    this.state = {
      activeItem: offers[0].id,
    };
  }

  componentDidMount() {
    this.startAutoAnimate();
  }

  componentWillUnmount() {
    this.stopAutoAnimate();
  }

  autoAnimate = () => {
    const { offers } = this.props;

    return setInterval(() => {
      if (!this.timer) return;

      this.setState(({ activeItem }) => {
        switch (activeItem) {
          case '1':
            return { activeItem: offers[1].id };
          case '2':
            return { activeItem: offers[3].id };
          case '3':
            return { activeItem: offers[0].id };
          case '4':
            return { activeItem: offers[2].id };
          default:
            return { activeItem: offers[0].id };
        }
      });
    }, 2000);
  };

  startAutoAnimate = () => {
    if (!this.timer) {
      this.timer = this.autoAnimate();
    }
  };

  stopAutoAnimate = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  setActiveItem = (activeItem) => this.setState({ activeItem });

  render() {
    const { offers, classes } = this.props;
    const { activeItem } = this.state;

    return (
      <div className={classes.container}>
        {offers.map(({ id, ...rest }) => (
          <Item
            key={id}
            offerId={id}
            pose={activeItem === id ? 'isActive' : 'isNotActive'}
            startAutoAnimate={this.startAutoAnimate}
            stopAutoAnimate={this.stopAutoAnimate}
            setActiveItem={this.setActiveItem}
            {...rest}
          />
        ))}
      </div>
    );
  }
}

Offers.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Offers;
