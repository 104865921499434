/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import DropDown from '@/components/DropDown';

const OffersMobile = ({ offers, classes }) => (
  <div className={classes.container}>
    {offers.map(({ id, title, items }) => (
      <DropDown key={id} title={title}>
        <ul className={classes.ul}>
          {items.map((item, index) => (
            <li key={index} className={classes.li}>
              {item}
            </li>
          ))}
        </ul>
      </DropDown>
    ))}
  </div>
);

OffersMobile.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default OffersMobile;
