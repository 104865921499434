/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Item from '@/components/ServiceBlock/Item';
import SubItem from '@/components/ServiceBlock/SubItem';

const SlideContainer = ({ classes, services, activeIndex, width }) => {
  const itemWidth = width * 0.75;

  return (
    <div className={classes.container}>
      {services.map((items, index) => {
        if (items.length === 0) return null;

        if (items.length === 1) {
          const { id, text, icon } = items[0];

          return <Item key={id} itemId={id} text={text} active={activeIndex === index} icon={icon} width={itemWidth} />;
        }

        return (
          <Item key={index} width={itemWidth}>
            {items.map(({ id, text, icon }) => (
              <SubItem key={id} itemId={id} text={text} active={activeIndex === index} icon={icon} />
            ))}
          </Item>
        );
      })}
    </div>
  );
};

SlideContainer.propTypes = {
  services: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  activeIndex: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default SlideContainer;
