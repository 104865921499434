export default ({ spacing }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: [spacing * 4, spacing * 7.5, spacing * 6, spacing * 7.5],
    '@media (max-width: 799px)': {
      display: 'none',
    },
  },
});
