import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SlideContainer from './SlideContainer';

class ServiceMobile extends Component {
  constructor() {
    super();
    this.state = {
      activeIndex: 0,
      width: (typeof window !== 'undefined' && window.innerWidth) || 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
    const { innerWidth } = e.target;
    const { width } = this.state;

    if (innerWidth !== width) {
      this.setState({ width: innerWidth });
    }
  };

  handleTouchStart = (e) => {
    this.touchStart = e.changedTouches[0].pageX;
  };

  handleTouchEnd = (e) => {
    const offset = e.changedTouches[0].pageX - this.touchStart;
    const { width } = this.state;

    if (offset < 0 && offset * -1 > width / 5) {
      this.getItemOffset('+');
    }

    if (offset > width / 5) {
      this.getItemOffset('-');
    }

    this.touchStart = null;
  };

  handleMouseDown = (event) => {
    this.startPoint = event.pageX;
  };

  handleMouseUp = (event) => {
    const offset = event.pageX - this.startPoint;
    if (offset < 50) {
      this.getItemOffset('+');
    }
    if (offset > 50) {
      this.getItemOffset('-');
    }
    this.startPoint = null;
  };

  getItemOffset = (direction) => {
    const { activeIndex } = this.state;
    const { services } = this.props;

    if (direction === '+' && activeIndex + 1 < services.length) {
      this.setState({ activeIndex: activeIndex + 1 });
    }
    if (direction === '-' && activeIndex - 1 >= 0) {
      this.setState({ activeIndex: activeIndex - 1 });
    }
  };

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        className={classes.view}
        role="presentation"
      >
        <SlideContainer {...this.state} {...rest} />
      </div>
    );
  }
}

ServiceMobile.propTypes = {
  services: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ServiceMobile;
