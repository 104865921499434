export default {
  container: {
    display: 'flex',
    position: 'relative',
    top: 0,
    left: ({ width, activeIndex }) => {
      const offset = 0.125 * width;
      const itemWidth = width * 0.75;

      return -1 * activeIndex * itemWidth + offset;
    },
    width: ({ width, services: { length } }) => width * 0.75 * length,
    transition: 'left 0.25s ease',
  },
};
