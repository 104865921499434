import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Description from '@/components/Description';
import ServiceBlock from '@/components/ServiceBlock';
import ServiceMobile from '@/components/ServiceMobile';
import Offers from '@/components/Offers';
import OffersMobile from '@/components/OffersMobile';
import Step1 from '@/icons/Step1';
import Step2 from '@/icons/Step2';
import Step3 from '@/icons/Step3';

const icons = {
  step1: Step1,
  step2: Step2,
  step3: Step3,
};

const Services = ({ pageContext }) => {
  const { description, services, offers } = pageContext;

  const items = useMemo(
    () =>
      services.map((service) =>
        service.map(({ icon, ...rest }) => ({
          icon: icon && icons[icon],
          ...rest,
        }))
      ),
    [services]
  );

  return (
    <>
      <Description title={description.services.title} text={description.services.text} />
      <ServiceBlock services={items} />
      <ServiceMobile services={items} />
      <Description title={description.offers.title} text={description.offers.text} />
      <Offers offers={offers} />
      <OffersMobile offers={offers} />
    </>
  );
};

Services.propTypes = {
  pageContext: PropTypes.shape({
    description: PropTypes.shape({
      services: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
      offers: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
    }).isRequired,
    services: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
    offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default Services;
export { default as Head } from '@/components/Head';
