import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <g fill="#000" fillRule="evenodd" transform="translate(12 7)">
      <path d="M9.71 64.73a.5.5 0 0 0 .5.5h42.95a.5.5 0 1 0 0-1H10.21a.5.5 0 0 0-.5.5zM53.16 69.9H10.21a.5.5 0 1 0 0 1h42.95a.5.5 0 1 0 0-1zM53.16 75.56H10.21a.5.5 0 1 0 0 1h42.95a.5.5 0 1 0 0-1zM20.77 25.75h21.48a.5.5 0 1 0 0-1H20.77a.5.5 0 1 0 0 1z" />
      <path
        fillRule="nonzero"
        d="M10.21 28.5h5.5a.51.51 0 0 0 .5-.5v-5.5a.5.5 0 0 0-.5-.5h-5.5a.5.5 0 0 0-.5.5V28a.5.5 0 0 0 .5.5zm.5-5.5h4.5v4.5h-4.5V23z"
      />
      <path d="M20.77 34.87h21.48a.5.5 0 1 0 0-1H20.77a.5.5 0 1 0 0 1z" />
      <path
        fillRule="nonzero"
        d="M10.21 37.62h5.5a.51.51 0 0 0 .5-.5v-5.5a.5.5 0 0 0-.5-.5h-5.5a.5.5 0 0 0-.5.5v5.5a.5.5 0 0 0 .5.5zm.5-5.5h4.5v4.5h-4.5v-4.5z"
      />
      <path d="M20.77 44h21.48a.5.5 0 1 0 0-1H20.77a.5.5 0 1 0 0 1z" />
      <path
        fillRule="nonzero"
        d="M10.21 46.74h5.5a.5.5 0 0 0 .5-.5v-5.5a.51.51 0 0 0-.5-.5h-5.5a.5.5 0 0 0-.5.5v5.5a.5.5 0 0 0 .5.5zm.5-5.5h4.5v4.5h-4.5v-4.5z"
      />
      <path d="M20.77 53.12h21.48a.5.5 0 1 0 0-1H20.77a.5.5 0 1 0 0 1z" />
      <path
        fillRule="nonzero"
        d="M10.21 55.87h5.5a.51.51 0 0 0 .5-.5v-5.5a.5.5 0 0 0-.5-.5h-5.5a.5.5 0 0 0-.5.5v5.5a.5.5 0 0 0 .5.5zm.5-5.5h4.5v4.5h-4.5v-4.5z"
      />
      <circle cx="31.64" cy="4.81" r="1" />
      <path
        fillRule="nonzero"
        d="M76.46 15.73H63.92v-.6a7.57 7.57 0 0 0-7.56-7.56H43.91v-.28A3 3 0 0 0 41 4.34h-5a4.31 4.31 0 0 0-8.57 0h-5a3 3 0 0 0-2.95 2.95v.28H8a7.57 7.57 0 0 0-7.56 7.56V78A7.57 7.57 0 0 0 8 85.57h48.36A7.57 7.57 0 0 0 63.92 78V42.82h12.54a.5.5 0 0 0 .5-.5V16.23a.5.5 0 0 0-.5-.5zM20.38 8.57V7.29a2 2 0 0 1 1.95-1.95h6v-.5a3.32 3.32 0 0 1 6.63 0v.5h6a2 2 0 0 1 1.95 1.95v3A2 2 0 0 1 41 12.21H22.33a2 2 0 0 1-1.95-1.95V8.57zM62.92 78a6.56 6.56 0 0 1-6.56 6.56H8A6.56 6.56 0 0 1 1.42 78V15.13A6.56 6.56 0 0 1 8 8.57h11.4v1.69a3 3 0 0 0 2.95 2.95H41a3 3 0 0 0 2.95-2.95V8.57h12.41a6.56 6.56 0 0 1 6.56 6.56v.6H50.38a.5.5 0 0 0-.5.5v26.09a.5.5 0 0 0 .5.5h12.54V78zM76 41.82H50.88V16.73H76v25.09z"
      />
      <path
        fillRule="nonzero"
        d="M63.42 27.6a1.69 1.69 0 0 0-1.68 1.68 1.67 1.67 0 0 0 1.18 1.59 1.7 1.7 0 0 0 .5.09 1.64 1.64 0 0 0 .5-.09 1.67 1.67 0 0 0 1.18-1.59 1.69 1.69 0 0 0-1.68-1.68zm.5 2.13a.66.66 0 0 1-.5.23.71.71 0 0 1-.5-.23.65.65 0 0 1 0-.9.67.67 0 0 1 .5-.23.7.7 0 0 1 .68.68.67.67 0 0 1-.18.45z"
      />
      <path
        fillRule="nonzero"
        d="M63.42 25a4.23 4.23 0 0 0-.5.05 4.28 4.28 0 0 0 0 8.51c.167.01.333.01.5 0a4 4 0 0 0 .5 0 4.28 4.28 0 0 0 0-8.51 4 4 0 0 0-.5-.05zm3.31 4.31a3.29 3.29 0 0 1-2.81 3.25 2.73 2.73 0 0 1-.5 0 2.83 2.83 0 0 1-.5 0 3.29 3.29 0 0 1 0-6.51 4.23 4.23 0 0 1 .5-.05 4 4 0 0 1 .5.05 3.3 3.3 0 0 1 2.81 3.23v.03z"
      />
      <path
        fillRule="nonzero"
        d="M63.42 23.18h-.5a11.47 11.47 0 0 0-9.5 5.82l-.14.25.14.24a11.47 11.47 0 0 0 9.5 5.81h1a11.5 11.5 0 0 0 9.51-5.81l.14-.24-.14-.25a11.5 11.5 0 0 0-9.51-5.81c-.17.02-.33-.01-.5-.01zm9 6.1a10.5 10.5 0 0 1-8.49 5.06h-1a10.53 10.53 0 0 1-8.49-5.06 10.5 10.5 0 0 1 8.49-5.06h1a10.47 10.47 0 0 1 8.48 5.06h.01z"
      />
    </g>
  </svg>
);
