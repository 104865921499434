export default ({ spacing }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: [spacing * 5, 0, spacing * 12],
    '@media (max-width: 799px)': {
      display: 'none',
    },
  },
});
