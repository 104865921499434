export default ({ spacing }) => ({
  view: {
    overflow: 'hidden',
    zIndex: 0,
    width: '100vw',
    boxShadow: ['inset', 0, 1, 23, 0, 'rgba(0, 0, 0, 0.14)'],
    background: '#f8f8f8',
    margin: [spacing * 4, 0],
    cursor: 'pointer',
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },
});
