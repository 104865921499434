import React from 'react';
import PropTypes from 'prop-types';

import Title from '@/components/Title';

const SubItem = ({ itemId, text, icon, classes }) => {
  const Icon = icon;

  return (
    <div className={classes.container}>
      <div className={classes.topPart}>
        <Title>{itemId}</Title>
        <div className={classes.imgContainer}>{icon && <Icon />}</div>
      </div>
      <p className={classes.text}>{text}</p>
    </div>
  );
};

SubItem.defaultProps = {
  icon: null,
};

SubItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default SubItem;
