/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getIds } from './utils';
import Item from './Item';
import SubItem from './SubItem';

class ServiceBlock extends Component {
  constructor({ services }) {
    super();
    this.state = {
      ids: getIds(services),
      activeItemIds: [],
    };
  }

  componentDidMount() {
    this.startAnimate();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startAnimate = () => {
    this.timer = setInterval(() => {
      const { ids, activeItemIds } = this.state;

      if (activeItemIds.length < ids.length) {
        this.setState({ activeItemIds: [...activeItemIds, ids[activeItemIds.length]] });
      } else {
        clearInterval(this.timer);
      }
    }, 2000);
  };

  render() {
    const { activeItemIds } = this.state;
    const { services, classes } = this.props;

    return (
      <div className={classes.container}>
        {services.map((items, index) => {
          if (items.length === 0) return null;

          if (items.length === 1) {
            const { id, text, icon } = items[0];

            return <Item key={id} itemId={id} text={text} active={activeItemIds.includes(id)} icon={icon} />;
          }

          return (
            <Item key={index}>
              {items.map(({ id, text, icon }) => (
                <SubItem key={id} itemId={id} text={text} active={activeItemIds.includes(id)} icon={icon} />
              ))}
            </Item>
          );
        })}
      </div>
    );
  }
}

ServiceBlock.propTypes = {
  services: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ServiceBlock;
