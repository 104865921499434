import { createUseStyles } from 'react-jss';

export default createUseStyles(({ colors, components, spacing, fonts }) => {
  const { height, background } = components.dropDown;

  return {
    titleContainer: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: height.desktop,
      width: '100%',
      paddingLeft: spacing * 2.5,
      background,
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      boxShadow: [0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)'],
      '@media (max-width: 799px)': {
        justifyContent: 'center',
        height: height.mobile,
      },
    },
    title: {
      ...fonts.subtitleMedium,
      margin: 0,
      '@media (max-width: 799px)': {
        ...fonts.subtitleMedium['@media (max-width: 799px)'],
        marginRight: spacing * 3,
      },
    },
    titleLight: {
      '@media (max-width: 799px)': {
        color: colors.active,
      },
    },
    imageMobileWrapper: {
      position: 'absolute',
      right: spacing * 2,
      '@media (min-width: 800px)': {
        display: 'none',
      },
    },
    imageDesktopWrapper: {
      right: spacing * 2,
      '@media (max-width: 799px)': {
        display: 'none',
      },
    },
    contentContainer: {
      overflow: 'hidden',
      height: 0,
      opacity: 0,
      paddingLeft: spacing * 2,
      paddingRight: spacing * 3,
      '@media (max-width: 799px)': {
        boxShadow: '0 2px 23px 0 rgba(204, 204, 204, 0.5)',
      },
    },
  };
});
