/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { containerVariants, titleVariants } from './utils';

class Item extends Component {
  handleMouseOver = () => {
    const { offerId, stopAutoAnimate, setActiveItem } = this.props;

    stopAutoAnimate();
    setActiveItem(offerId);
  };

  handleMouseOut = () => {
    const { startAutoAnimate } = this.props;

    startAutoAnimate();
  };

  render() {
    const { title, items, pose, classes } = this.props;

    return (
      <motion.div
        className={classes.container}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onFocus={this.handleMouseOver}
        onBlur={this.handleMouseOut}
        variants={containerVariants}
        animate={pose}
        initial="isNotActive"
      >
        <motion.p className={classes.title} variants={titleVariants} animate={pose} initial="isNotActive">
          {title}
        </motion.p>
        <ul>
          {items.map((item, index) => (
            <li className={classes.item} key={index}>
              {item}
            </li>
          ))}
        </ul>
      </motion.div>
    );
  }
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  pose: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  offerId: PropTypes.string.isRequired,
  stopAutoAnimate: PropTypes.func.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  startAutoAnimate: PropTypes.func.isRequired,
};

export default Item;
