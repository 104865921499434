import React from 'react';
import PropTypes from 'prop-types';

import Title from '@/components/Title';

const Item = ({ itemId, text, icon, children, classes }) => {
  const Icon = icon;

  return children ? (
    <div className={classes.collectionContainer}>{children}</div>
  ) : (
    <div className={classes.container}>
      <Title>{itemId}</Title>
      <div className={classes.imgContainer}>{icon && <Icon />}</div>
      <p className={classes.text}>{text}</p>
    </div>
  );
};

Item.defaultProps = {
  children: null,
  itemId: null,
  text: null,
  icon: null,
};

Item.propTypes = {
  itemId: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Item;
