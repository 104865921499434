import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <g fill="#000" fillRule="nonzero">
      <path d="M101.12 49.61a57.88 57.88 0 0 0-100.85 0 .53.53 0 0 0 0 .49 57.88 57.88 0 0 0 100.85 0 .53.53 0 0 0 0-.49zm-50.43 29A57 57 0 0 1 1.28 49.86a56.87 56.87 0 0 1 98.82 0 57 57 0 0 1-49.41 28.71v.04z" />
      <path d="M43.66 27.25A23.68 23.68 0 1 0 73.3 42.83a23.54 23.54 0 0 0-29.64-15.58zm28.69 15.87a22.36 22.36 0 0 1 .88 4.27 89.16 89.16 0 0 1-16.83 8.76 8.37 8.37 0 0 0 1.82-2.34c.08-.16.15-.32.22-.48 2.25-3.64 2.9-17.28 2.44-23.72a22.63 22.63 0 0 1 11.47 13.51zm-28.82 9a7.49 7.49 0 0 1 10.65-8.87A7.5 7.5 0 0 1 52.92 57a7.49 7.49 0 0 1-9.39-4.93v.05zM44 28.2a22.53 22.53 0 0 1 15.87.93A89.76 89.76 0 0 1 59 48.06c-.06-.24-.12-.49-.19-.73a8.45 8.45 0 0 0-1.25-2.47c-2.07-3.78-13.53-11.14-19.32-14A22.61 22.61 0 0 1 44 28.2zM30.62 39.31a22.63 22.63 0 0 1 6.68-7.74 89.75 89.75 0 0 1 16 10.2 8.4 8.4 0 0 0-3.52-.36h-.08c-4.39 0-16.34 6.17-21.63 9.75a22.49 22.49 0 0 1 2.55-11.85zM29 56.59a22.52 22.52 0 0 1-.89-4.27A90 90 0 0 1 45 43.55a8.45 8.45 0 0 0-1.83 2.35c-.08.16-.15.33-.23.49C40.7 50 40.05 63.67 40.51 70.1c-.12-.06-.25-.1-.37-.17A22.52 22.52 0 0 1 29 56.59zm28.43 14.92a22.47 22.47 0 0 1-15.88-.92 90.48 90.48 0 0 1 .84-18.93c.05.243.113.484.19.72a8.48 8.48 0 0 0 1.24 2.48c2.07 3.77 13.54 11.14 19.33 14a22.94 22.94 0 0 1-5.72 2.65zm6.66-3.36a89.47 89.47 0 0 1-16-10.22 8.29 8.29 0 0 0 2.6.43 8.48 8.48 0 0 0 .88-.06h.13c4.39 0 16.36-6.19 21.64-9.77a22.76 22.76 0 0 1-9.25 19.62z" />
    </g>
  </svg>
);
