import { colors } from '@/theme';

const transition = { duration: 0.5, ease: 'easeInOut' };

export const containerVariants = {
  isActive: {
    border: '1px solid #fff',
    boxShadow: '0 0 30px 1px rgba(0,0,0,0.2)',
    transition,
  },
  isNotActive: {
    border: '1px solid #d0d0d0',
    boxShadow: '0 0 0px 0px rgba(0,0,0,0.2)',
    transition,
  },
};

export const titleVariants = {
  isActive: { color: colors.active, transition },
  isNotActive: { color: '#000', transition },
};
